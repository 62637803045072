import React from 'react';
import Trans from 'next-translate/Trans';
import { CodeClipboard } from '@dotone/react-ui-core';

const Clipboard = (props) => <CodeClipboard className="pb-2" language="html" {...props} />;

const DocsTranslate = ({ tKey, components, code, clipboardProps, ...otherProps }) => (
  <Trans
    i18nKey={`docs:${tKey}`}
    components={{
      h5: <h5 className="text-dark-light" />,
      li: <li />,
      ol: <ol />,
      strong: <strong />,
      em: <em />,
      code: <Clipboard value={code} {...clipboardProps} />,
      ...components,
    }}
    {...otherProps}
  />
);

export default DocsTranslate;
