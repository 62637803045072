import API from './api';

export default class ReportApi {
  static getStatSummaries(params) {
    return API.get('/advertisers/reports/stat_summaries', params);
  }

  static getAffiliations(params) {
    return API.get('/advertisers/reports/affiliations', params);
  }

  static downloadStatSummaries(params) {
    return API.post('/advertisers/reports/stat_summaries/download', params);
  }

  static getTaskSummary(params) {
    return API.get('/advertisers/reports/tasks', params);
  }
}
