import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useShallowEqualSelector } from '@hooks';
import { useForm } from '@dotone/react-ui-core/hooks';
import { createSearchKey, dismissCreateSearchKey } from '@actions';
import { camelCase, snakeCase, trim } from 'lodash';
import { formatDateRange, formatDate } from '@dotone/react-ui-core/utils';
import { Form } from 'reactstrap';
import {
  FormGroup,
  FormAction,
  Label,
  Input,
  Button,
  ButtonCancel,
  StatSearchFieldSelect,
} from '@dotone/react-ui-core';

const StatSearchForm = ({
  defaultValues: givenDefaultValues = {},
  onSubmit,
  onDismiss,
  ...otherProps
}) => {
  const { t } = useTranslate('stat', 'search');
  const dispatch = useDispatch();

  const {
    data: { searchKey },
    isLoading: submitting,
    status,
  } = useShallowEqualSelector(({ searchKeyCreate }) => searchKeyCreate);

  const { field, search = [] } = givenDefaultValues;
  const defaultValues = {
    ...givenDefaultValues,
    field: field ? camelCase(field) : null,
    search: search.join('\n'),
  };

  const { handleSubmit, connectForm, reset } = useForm({ defaultValues });

  const defaultFilters = () => {
    const startDate = formatDate(new Date()).subtract(2, 'year');
    const endDate = formatDate(new Date());

    return formatDateRange({ startDate, endDate });
  };

  const handleSearchKeyChange = useCallback(
    (value) => {
      onSubmit({ searchKey: value }, value ? defaultFilters() : formatDateRange());
    },
    [onSubmit]
  );

  const handleReset = () => {
    handleSearchKeyChange(null);
    reset();
  };

  const handleSubmitForm = (values) => {
    const data = {
      ...values,
      search: values.search.split('\n').map(trim),
      field: snakeCase(values.field),
    };

    dispatch(createSearchKey(data));
  };

  useEffect(() => {
    if (searchKey && status === 'success' && !submitting) {
      dispatch(dismissCreateSearchKey());
      handleSearchKeyChange(searchKey);
    }
  }, [dispatch, searchKey, status, submitting, handleSearchKeyChange]);

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)} {...otherProps}>
      {connectForm(
        <>
          <FormGroup>
            <Label>{t('searchBy')}</Label>
            <StatSearchFieldSelect name="field" rules={{ required: true }} />
          </FormGroup>
          <FormGroup>
            <Label>{t('search')}</Label>
            <Input type="textarea" name="search" rules={{ required: true }} rows={5} />
          </FormGroup>
        </>
      )}
      <FormAction align="right">
        <Button type="submit" color="primary" submitting={submitting}>
          {t('common:actions.submit')}
        </Button>
        <Button color="secondary" onClick={handleReset}>
          {t('common:actions.reset')}
        </Button>
        <ButtonCancel disabled={submitting} onClick={onDismiss} />
      </FormAction>
    </Form>
  );
};

export default StatSearchForm;
