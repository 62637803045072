import { SearchKeyApi } from '@api';
import { searchKeyTypes as actionTypes } from './types';

export const createSearchKey = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE.LOADING,
  });

  SearchKeyApi.create({ searchKey: params })
    .then((response) => {
      dispatch({
        type: actionTypes.CREATE.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE.FAILURE,
        message: `notify:general.save.error.${error.status}`,
      });
    });
};

export const dismissCreateSearchKey = () => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE.DISMISS_ALERT,
  });
};
