import API from './api';

export default class AffiliateStatApi {
  static getList({ dataType, ...params }) {
    return API.get(`/advertisers/affiliate_stats/${dataType}`, params);
  }

  static getPending(params) {
    return API.get('/advertisers/affiliate_stats/pending_conversions', params);
  }

  static getPendingByOffer(params) {
    return API.get('/advertisers/affiliate_stats/pending_conversions_by_offer', params);
  }

  static download(params) {
    return API.post(`/advertisers/affiliate_stats/download`, params);
  }

  static bulkUpdate(params) {
    return API.post('/advertisers/affiliate_stats/bulk_update', params);
  }
}
