import API from './api';

export default class ClientApiApi {
  static getList(params) {
    return API.get('/advertisers/client_apis', params);
  }

  static create(params) {
    return API.post('/advertisers/client_apis', params);
  }

  static update(id, params) {
    return API.put(`/advertisers/client_apis/${id}`, params);
  }
}
