import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslate, useCurrentUser, useFilterSelector, useSearchSelector } from '@hooks';
import { useForm } from '@dotone/react-ui-core/hooks';
import { searchOffers } from '@actions';
import { getOffer } from '@actions/v1';
import { FormGroup, Label, Form } from 'reactstrap';
import Trans from 'next-translate/Trans';
import {
  InputClipboard,
  Text,
  MessageEmpty,
  ApiKeysClipboard,
  Alert,
  NextLink,
} from '@dotone/react-ui-core';
import { OfferSearchInput } from '../forms';
import { useApiUrl } from './helpers';

const Section = (props) => <div className="mb-3" {...props} />;

const UrlBlock = ({ title, subtitle, urlParams, apiUrl }) => (
  <>
    {title && <h6 className="text-dark-light">{title}</h6>}
    <Text>{subtitle}</Text>
    <InputClipboard
      value={apiUrl({
        order: 'test-order-123',
        order_total: 5400,
        ...urlParams,
      })}
      className="mb-3"
    />
  </>
);

const DocsModifyOrder = () => {
  const { t, tHtml, locale } = useTranslate('docs', 'modifyOrder');
  const router = useRouter();
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const { connectForm, watch, setValue } = useForm();
  const offerId = watch('offerId');
  const { offer_id: givenOfferId } = router.query;
  const { apiKey, apiKeys, apiUrl } = useApiUrl();
  const { data: publicOffer } = useFilterSelector(({ apiOfferCurrent }) => apiOfferCurrent, {
    id: givenOfferId,
    locale,
  });
  const formatApiUrl = (params = {}) => {
    return apiUrl('advertisers/orders/modify', params);
  };

  const { data: offers } = useSearchSelector(({ offerSearch }) => offerSearch, {
    active: true,
    locale,
  });

  const offer = offers[0] || publicOffer;

  useEffect(() => {
    if (currentUser.id && !offers.length) {
      dispatch(searchOffers({ active: true, locale }));
    }
  }, [dispatch, offers.length, currentUser.id, locale]);

  useEffect(() => {
    if (currentUser.id && !offerId && !!offers.length) setValue('offerId', offer.id);
  }, [currentUser.id, offerId, offers.length, offer.id, setValue]);

  useEffect(() => {
    if (!currentUser.id && givenOfferId && apiKey) {
      dispatch(getOffer(givenOfferId, { apiKey, locale }));
    }
  }, [dispatch, givenOfferId, currentUser.id, offerId, offer.id, apiKey, locale]);

  return apiKey ? (
    <>
      {offers.length > 1 && (
        <Form>
          {connectForm(
            <FormGroup>
              <Label>{t('common:model.offer')}</Label>
              <OfferSearchInput active name="offerId" />
            </FormGroup>
          )}
        </Form>
      )}
      {offer.id ? (
        <>
          <ApiKeysClipboard apiKeys={apiKeys} />
          <Section>
            <h6 className="text-dark-light">{t('requestUrl')}</h6>
            <InputClipboard value={formatApiUrl()} className="mb-3" />
          </Section>

          <Section>
            <div>{tHtml('params', { apiKey })}</div>
          </Section>

          <Section>
            <UrlBlock
              title={t('sampleCps.title')}
              subtitle={tHtml('sampleCps.withOrderTotal')}
              urlParams={{ status: 'adjust', offer_id: offer.id }}
              apiUrl={formatApiUrl}
            />
            <UrlBlock
              subtitle={tHtml('sampleCps.withRevenue')}
              urlParams={{ status: 'confirm', revenue: 5400, offer_id: offer.id }}
              apiUrl={formatApiUrl}
            />
          </Section>

          <Section>
            <UrlBlock
              title={t('sampleCpl.title')}
              subtitle={tHtml('sampleCpl.withStep')}
              urlParams={{
                server_subid: '9142ef919dd612b6add8e625ee212f2b',
                status: 'confirm',
                step: 'sale',
              }}
              apiUrl={formatApiUrl}
            />
            <UrlBlock
              subtitle={tHtml('sampleCpl.withServerSubid')}
              urlParams={{
                server_subid: '9142ef919dd612b6add8e625ee212f2b',
                status: 'confirm',
              }}
              apiUrl={formatApiUrl}
            />
          </Section>

          <Section>
            <div>{tHtml('response', { apiKey })}</div>
          </Section>
        </>
      ) : (
        <MessageEmpty />
      )}
    </>
  ) : (
    <Alert variant="warning">
      <Trans
        i18nKey="docs:modifyOrder.apiKeyRequired"
        components={[<NextLink key="link" href="/advertisers/api-keys" />]}
      />
    </Alert>
  );
};

export default DocsModifyOrder;
