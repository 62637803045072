import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useCurrentUser, useFilterSelector } from '@hooks';
import { apiKeyStatuses } from '@dotone/react-ui-core/constants';
import { fetchApiKeys } from '@actions';
import { envVars } from '@constants';

export const useApiUrl = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const { api_key: givenApiKey } = router.query;
  const { data } = useFilterSelector(({ apiKeyList }) => apiKeyList, {
    status: apiKeyStatuses.ACTIVE,
  });
  const apiKeys = data.map((item) => item.value);
  let apiKey = givenApiKey;
  if (currentUser.id) apiKey = currentUser.id && apiKeys?.includes(apiKey) ? apiKey : apiKeys[0];

  useEffect(() => {
    if (currentUser.id && !apiKeys.length) {
      dispatch(fetchApiKeys({ status: apiKeyStatuses.ACTIVE }));
    }
  }, [dispatch, apiKeys.length, currentUser.id]);

  const apiUrl = (path, givenParams = null) => {
    const url = new URL(`${envVars.apiV2BaseUrl}/${path}`);
    if (givenParams) {
      const params = new URLSearchParams({ api_key: apiKey, ...givenParams });
      url.search = params.toString();
    }
    return url.toString();
  };

  return { apiUrl, apiKey, apiKeys: apiKeys.length ? apiKeys : [apiKey] };
};
