import React from 'react';
import { uniq } from 'lodash';
import { ProductApiNames } from '@constants';
import { Input } from '@dotone/react-ui-core';

const ProductApiNameSelect = ({ defaultValues, ...otherProps }) => {
  const AllowedProductApiNames = defaultValues.name
    ? uniq([defaultValues.name, ...ProductApiNames])
    : ProductApiNames;

  const options = Object.values(AllowedProductApiNames).map((value) => ({
    value,
    label: value,
  }));
  return <Input type="select" options={options} {...otherProps} />;
};

export default ProductApiNameSelect;
