import React from 'react';
import { useTranslate, useCurrentUser } from '@hooks';
import { currencySymbols } from '@dotone/react-ui-core/constants';
import { toNumberWithDelimeter, getNameWithId } from '@dotone/react-ui-core/utils';
import { TablePaginated, Currency, Date } from '@dotone/react-ui-core';
import FilterForm from './StatSummaryFilterForm';
import ColumnForm from './StatSummaryColumnForm';
import FilterList from './StatSummaryFilterList';

const formatCount = (col, dataTotal) => toNumberWithDelimeter(dataTotal[col], { round: true });

const formatPercentage = (cell) => toNumberWithDelimeter(cell);

const formatCurrency = (cell) => <Currency round={false} value={cell} currencyHidden />;

const formatDate = (cell) => <Date utc value={cell} />;

const formatInt = (cell) => toNumberWithDelimeter(cell, { round: true });

const getColumns = ({ t, periodical, currencySymbol, filters, columns, dataTotal }) => [
  {
    dataField: 'date',
    text: t('date'),
    style: { whiteSpace: 'pre' },
    hidden: !periodical,
    sort: true,
    formatter: formatDate,
    footer: t('total'),
  },
  {
    dataField: 'offerId',
    text: t('offerId'),
    hidden: !(filters.dimension === 'offerId' || columns.includes('offerId')),
    sort: true,
    formatter: (cell, { offer }) => (offer ? getNameWithId(offer) : ''),
    footer: '',
  },
  {
    dataField: 'affiliateId',
    text: t('affiliateId'),
    hidden: !(filters.dimension === 'affiliateId' || columns.includes('affiliateId')),
    sort: true,
    formatter: (cell) => cell || '--',
    footer: '',
  },
  {
    dataField: 'affiliate.mediaCategories',
    text: t('mediaCategory'),
    hidden: !columns.includes('affiliateId') || !columns.includes('mediaCategory'),
    alias: 'mediaCategory',
    formatter: (cell) => cell?.map((item) => item.name)?.join(', '),
    footer: '',
  },
  {
    dataField: 'imageCreativeId',
    text: t('imageCreativeId'),
    hidden: !(filters.dimension === 'imageCreativeId' || columns.includes('imageCreativeId')),
    sort: true,
    formatter: (cell, { imageCreative }) =>
      imageCreative ? `(${imageCreative.id}) ${imageCreative.size}` : '--',
    footer: '',
  },
  {
    dataField: 'textCreativeId',
    text: t('textCreativeId'),
    hidden: !(filters.dimension === 'textCreativeId' || columns.includes('textCreativeId')),
    sort: true,
    formatter: (cell, { textCreative }) =>
      textCreative ? `(${textCreative.id}) ${textCreative.creativeName}` : '--',
    footer: '',
  },
  {
    dataField: 'impressions',
    text: t('impressions'),
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    hintProps: { t, tKey: 'statSummary:hints.impressions' },
    sort: true,
    formatter: formatInt,
    footer: () => formatCount('impressions', dataTotal),
  },
  {
    dataField: 'clicks',
    text: t('clicks'),
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    sort: true,
    formatter: formatInt,
    footer: () => formatCount('clicks', dataTotal),
  },
  {
    dataField: 'captured',
    text: t('captured'),
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    hintProps: { t, tKey: 'statSummary:hints.captured' },
    sort: true,
    formatter: formatInt,
    footer: () => formatCount('captured', dataTotal),
  },
  {
    dataField: 'publishedConversions',
    text: t('publishedConversions'),
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    sort: true,
    formatter: formatInt,
    footer: () => formatCount('publishedConversions', dataTotal),
  },
  {
    dataField: 'pendingConversions',
    text: t('pendingConversions'),
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    sort: true,
    formatter: formatInt,
    footer: () => formatCount('pendingConversions', dataTotal),
  },
  {
    dataField: 'rejectedConversions',
    text: t('rejectedConversions'),
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    sort: true,
    formatter: formatInt,
    footer: () => formatCount('rejectedConversions', dataTotal),
  },
  {
    dataField: 'orderTotal',
    text: `${t('orderTotal')} (${currencySymbol})`,
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    hintProps: { t, tKey: 'statSummary:hints.orderTotal' },
    sort: true,
    formatter: formatCurrency,
    footer: () => formatCurrency(dataTotal.orderTotal),
  },
  {
    dataField: 'totalTruePay',
    text: `${t('totalTruePay')} (${currencySymbol})`,
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    hintProps: { t, tKey: 'statSummary:hints.totalTruePay' },
    sort: true,
    formatter: formatCurrency,
    footer: () => formatCurrency(dataTotal.totalTruePay),
  },
  {
    dataField: 'publishedTruePay',
    text: `${t('publishedTruePay')} (${currencySymbol})`,
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    sort: true,
    formatter: formatCurrency,
    footer: () => formatCurrency(dataTotal.publishedTruePay),
  },
  {
    dataField: 'pendingTruePay',
    text: `${t('pendingTruePay')} (${currencySymbol})`,
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    sort: true,
    formatter: formatCurrency,
    footer: () => formatCurrency(dataTotal.pendingTruePay),
  },
  {
    dataField: 'rejectedTruePay',
    text: `${t('rejectedTruePay')} (${currencySymbol})`,
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    sort: true,
    formatter: formatCurrency,
    footer: () => formatCurrency(dataTotal.rejectedTruePay),
  },
  {
    dataField: 'conversionPercentage',
    text: t('conversionPercentage'),
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    hintProps: { t, tKey: 'statSummary:hints.conversionPercentage' },
    sort: true,
    formatter: formatPercentage,
    footer: () => formatPercentage(dataTotal.conversionPercentage),
  },
  {
    dataField: 'truePayEpc',
    text: `${t('truePayEpc')} (${currencySymbol})`,
    align: 'right',
    footerAlign: 'right',
    headerAlign: 'right',
    sort: true,
    formatter: formatCurrency,
    footer: () => formatCurrency(dataTotal.truePayEpc),
  },
];

const StatSummaryTable = ({
  reportType = 'performance',
  periodical = false,
  dataTotal,
  ...otherProps
}) => {
  const { t } = useTranslate('statSummary', 'model', { fallbackNs: 'stat' });
  const { currency } = useCurrentUser();
  const {
    tableProps: { filters: tableFilters, columns: tableColumns },
  } = otherProps;
  const columns = getColumns({
    t,
    periodical,
    currencySymbol: currencySymbols[currency],
    filters: tableFilters,
    columns: tableColumns,
    dataTotal,
  });

  const tableProps = {
    classes: 'text-sm stylish-table',
    columns,
    condensed: true,
    responsive: true,
    headerClasses: 'text-wrap',
    filterListRenderer: (filters) => (
      <FilterList reportType={reportType} periodical={periodical} filters={filters} />
    ),
    filterRenderer: (filterProps) => (
      <FilterForm reportType={reportType} periodical={periodical} {...filterProps} />
    ),
    columnRenderer: (columnProps) => <ColumnForm reportType={reportType} {...columnProps} />,
    hover: true,
    keyField: 'id',
    remote: true,
    searchable: false,
    checkVisibility: true,
    ...otherProps,
  };

  return <TablePaginated {...tableProps} />;
};

export default StatSummaryTable;
