import { clientApiTypes as actionTypes } from '@actions';
import { commonState, commonReducer, pagination } from '@dotone/react-ui-core/helpers';

const defaultState = {
  ...commonState.default(),
};

const collectionState = {
  ...commonState.default({ collection: true }),
  pagination,
};

export const clientApiList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST, state, action);

export const clientApiCreate = (state = defaultState, action) =>
  commonReducer(actionTypes.CREATE, state, action);

export const clientApiUpdate = (state = defaultState, action) =>
  commonReducer(actionTypes.UPDATE, state, action);
