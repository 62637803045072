import React from 'react';
import { useTranslate } from '@hooks';
import { Modal, ModalWithInitialButton } from '@dotone/react-ui-core';
import Form from './ProductApiForm';

const ProductApiFormModal = ({ clientApi = {}, buttonProps, ...otherProps }) => {
  const { t } = useTranslate('clientApi', 'actions');

  let props = {
    title: clientApi.id ? t('::common:actions.edit') : t('add'),
    staticBackdrop: true,
    size: 'xl',
    footer: false,
    scrollable: false,
    ...otherProps,
  };

  if (buttonProps) {
    props = {
      dense: true,
      buttonProps,
      ...props,
    };
  }
  return buttonProps ? (
    <ModalWithInitialButton form={<Form defaultValues={clientApi} />} {...props} />
  ) : (
    <Modal {...props}>
      <Form defaultValues={clientApi} onCancel={otherProps.onToggle} />
    </Modal>
  );
};

export default ProductApiFormModal;
