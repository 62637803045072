import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslate } from '@hooks';
import { Row, Col } from 'reactstrap';
import Trans from 'next-translate/Trans';
import {
  TabSet,
  TabPane,
  InputClipboard,
  ApiKeysClipboard,
  Text,
  CodeClipboard,
  Alert,
  NextLink,
} from '@dotone/react-ui-core';
import { useApiUrl } from './helpers';

const formatResponse = (format, t) => {
  return (
    <Row>
      <Col lg="6">
        <h6 className="text-primary">{t('summary')}</h6>
        <div className="code text-sm">
          <CodeClipboard className="pb-2" language="javascript" value={t(`code.${format}`)} />
        </div>
      </Col>
      <Col lg="6">
        <h6 className="text-primary">{t('notes.title')}</h6>
        <Text>{t(`notes.${format}`)}</Text>
      </Col>
    </Row>
  );
};

const tabs = ['json', 'xml'];

const Section = (props) => <div className="mb-3" {...props} />;

const DocsSnippet = () => {
  const { t, tHtml } = useTranslate('docs', 'snippet');
  const router = useRouter();
  const { tab: givenTab } = router.query;
  const [activeTab, setActiveTab] = useState(tabs.includes(givenTab) ? givenTab : tabs[0]);
  const { apiUrl, apiKey, apiKeys } = useApiUrl();
  const items = {};
  tabs.forEach((tab) => {
    items[tab] = t(`tabs.${tab}`);
  });

  const handleTabClick = (value) => {
    setActiveTab(value);
  };

  return apiKey ? (
    <>
      <ApiKeysClipboard apiKeys={apiKeys} />
      <Section>
        <h5 className="text-dark-light">{t('requestUrl')}</h5>
        <Row>
          <Col lg={6}>
            <InputClipboard
              value={apiUrl('advertisers/snippets.json')}
              prepend={<span className="text-custom">{t('tabs.json')}:</span>}
            />
          </Col>
          <Col lg={6}>
            <InputClipboard
              value={apiUrl('advertisers/snippets.json.xml')}
              prepend={<span className="text-custom">{t('tabs.xml')}:</span>}
            />
          </Col>
        </Row>
      </Section>
      <Section>
        <div>{tHtml('params', { apiKey })}</div>
      </Section>
      <Section>
        <h5 className="text-dark-light">{t('response')}</h5>
        <TabSet
          card={false}
          items={items}
          activeTab={activeTab}
          navProps={{
            pills: true,
            className: 'font-medium custom-pills',
          }}
          onToggle={handleTabClick}
        >
          <TabPane tabId={activeTab} card={false}>
            {activeTab === 'json' && formatResponse(activeTab, t)}
            {activeTab === 'xml' && formatResponse(activeTab, t)}
          </TabPane>
        </TabSet>
      </Section>
    </>
  ) : (
    <Alert variant="warning">
      <Trans
        i18nKey="docs:snippet.apiKeyRequired"
        components={[<NextLink key="link" href="/advertisers/api-keys" />]}
      />
    </Alert>
  );
};

export default DocsSnippet;
