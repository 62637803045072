import { ReportApi } from '@api';
import { reportTypes as actionTypes } from './types';

export const fetchStatSummaries = (params, reportType) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST_STAT_SUMMARY.LOADING,
  });

  ReportApi.getStatSummaries(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST_STAT_SUMMARY.SUCCESS,
        payload: {
          ...response,
          stateKey: reportType,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST_STAT_SUMMARY.FAILURE,
        message: `notify:general.errors.${error?.status || '500'}`,
      });
    });
};

export const fetchAffiliations = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST_AFFILIATION.LOADING,
  });

  ReportApi.getAffiliations(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST_AFFILIATION.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST_AFFILIATION.FAILURE,
        message: `notify:general.errors.${error?.status || '500'}`,
      });
    });
};

export const downloadStatSummaries = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.DOWNLOAD_STAT_SUMMARY.LOADING,
  });

  ReportApi.downloadStatSummaries(params)
    .then((response) => {
      dispatch({
        type: actionTypes.DOWNLOAD_STAT_SUMMARY.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.DOWNLOAD_STAT_SUMMARY.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const fetchTaskSummary = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.TASK_SUMMARY.LOADING,
  });

  ReportApi.getTaskSummary(params)
    .then((response) => {
      dispatch({
        type: actionTypes.TASK_SUMMARY.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.TASK_SUMMARY.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
