import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useCurrentUser, useFilterSelector } from '@hooks';
import { fetchTaskSummary } from '@actions';
import { missingOrderStatuses, statApprovals } from '@dotone/react-ui-core/constants';
import { FlexContainer, Text, NextLink } from '@dotone/react-ui-core';
import { isEmpty } from 'lodash';

const TaskSummary = (props) => {
  const { t } = useTranslate('dashboard', 'tasks');
  const dispatch = useDispatch();
  const { currentUser, timeZone } = useCurrentUser();
  const { data, isLoading } = useFilterSelector(({ taskSummary }) => taskSummary, {
    timeZone,
  });
  const shouldFetch = isEmpty(data);

  const { pendingConversions, confirmingMissingOrders, startDate } = data;

  useEffect(() => {
    if (currentUser.id && shouldFetch) {
      dispatch(fetchTaskSummary());
    }
  }, [dispatch, currentUser.id, shouldFetch]);

  return (
    <FlexContainer {...props}>
      {!isLoading && (
        <>
          <FlexContainer column>
            <Text>{t('pendingConversions')}</Text>
            <NextLink
              href={`/advertisers/transactions/captured?startDate=${startDate}&approvals=${statApprovals.PENDING}`}
              className="text-danger"
            >
              {pendingConversions}
            </NextLink>
          </FlexContainer>
          <FlexContainer column className="ml-3">
            <Text>{t('confirmingMissingOrders')}</Text>
            <NextLink
              href={`/advertisers/order-inquiries?statuses=${missingOrderStatuses.CONFIRMING}`}
              className="text-danger"
            >
              {confirmingMissingOrders}
            </NextLink>
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );
};

export default TaskSummary;
