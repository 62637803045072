import React from 'react';
import { useTranslate } from '@hooks';
import { statSummaryDimensions } from '@constants';
import { compact, trim } from 'lodash';
import { toArray } from '@dotone/react-ui-core/utils';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { FormFilter as Form, DateRangePicker, Input } from '@dotone/react-ui-core';
import { OfferSearchInput } from '../forms';

const FilterSelect = ({ options, ...otherProps }) => {
  const { t } = useTranslate('statSummary', 'model', { fallbackNs: 'stat' });
  const mapOptions = options.map((key) => ({
    label: t(key),
    value: key,
  }));
  return <Input type="select" options={mapOptions} {...otherProps} />;
};

const StatSummaryFilterForm = ({
  periodical = false,
  defaultValues: givenDefaultValues,
  onSubmit,
  reportType,
  ...otherProps
}) => {
  const { t } = useTranslate('statSummary', 'model', { fallbackNs: 'stat' });

  const defaultValues = {
    ...givenDefaultValues,
    affiliateIds: toArray(givenDefaultValues.affiliateIds).join(','),
    imageCreativeIds: toArray(givenDefaultValues.imageCreativeIds).join(','),
    textCreativeIds: toArray(givenDefaultValues.textCreativeIds).join(','),
  };
  if (reportType === 'conversion') {
    defaultValues.dimension = givenDefaultValues.dimension || 'offerId';
  }

  const handleSubmit = (values) => {
    onSubmit({
      ...values,
      affiliateIds: compact(values.affiliateIds.split(',').map(trim)),
      imageCreativeIds: compact(values.imageCreativeIds.split(',').map(trim)),
      textCreativeIds: compact(values.textCreativeIds.split(',').map(trim)),
    });
  };

  return (
    <Form dateable defaultValues={defaultValues} onSubmit={handleSubmit} {...otherProps}>
      <Row>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('common:date.date')}</Label>
            <DateRangePicker forReport names={{ from: 'startDate', to: 'endDate' }} />
          </FormGroup>
        </Col>
        {!periodical && reportType !== 'performance' && (
          <Col md={6} lg={4} xl={3}>
            <FormGroup>
              <Label>{t('statSummary:columnGroup.dimensions')}</Label>
              <FilterSelect name="dimension" prompt={false} options={statSummaryDimensions} />
            </FormGroup>
          </Col>
        )}
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('offerId')}</Label>
            <OfferSearchInput multi name="offerIds" />
          </FormGroup>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('affiliateId')}</Label>
            <Input name="affiliateIds" />
          </FormGroup>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('imageCreativeId')}</Label>
            <Input multi name="imageCreativeIds" />
          </FormGroup>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('textCreativeId')}</Label>
            <Input multi name="textCreativeIds" />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default StatSummaryFilterForm;
