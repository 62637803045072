import React from 'react';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector, useTranslate } from '@hooks';
import { updatePassword } from '@actions';
import { FormGroup, Label } from 'reactstrap';
import { Lock as LockIcon } from 'react-feather';
import { Form, FormAction, InputGroup, Button } from '@dotone/react-ui-core';

const UserFormPassword = (props) => {
  const { t } = useTranslate('password', 'edit');
  const { tShared } = useTranslate('validation');
  const dispatch = useDispatch();
  const { isLoading } = useShallowEqualSelector(({ passwordUpdate }) => passwordUpdate);

  const onSubmit = (values) => {
    dispatch(updatePassword(values));
  };

  return (
    <Form onSubmit={onSubmit} {...props}>
      <FormGroup>
        <Label for="currentPassword">{t('currentPassword')}</Label>
        <InputGroup
          name="currentPassword"
          prepend={<LockIcon size={16} />}
          inputProps={{
            type: 'password',
            placeholder: t('currentPassword'),
            rules: {
              required: true,
            },
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="currentPassword">{t('newPassword')}</Label>
        <InputGroup
          name="password"
          prepend={<LockIcon size={16} />}
          inputProps={{
            type: 'password',
            placeholder: t('newPassword'),
            rules: {
              required: true,
            },
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="currentPassword">{t('passwordConfirmation')}</Label>
        <InputGroup
          name="passwordConfirmation"
          prepend={<LockIcon size={16} />}
          inputProps={{
            type: 'password',
            placeholder: t('passwordConfirmation'),
            rules: {
              required: true,
              match: {
                field: 'password',
                message: tShared('passwordMatch'),
              },
            },
          }}
        />
      </FormGroup>
      <FormAction className="mb-3">
        <Button block color="primary" type="submit" submitting={isLoading}>
          {t('submit')}
        </Button>
      </FormAction>
    </Form>
  );
};

export default UserFormPassword;
