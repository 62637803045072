import React from 'react';
import { useTranslate } from '@hooks';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import { TablePaginated, ClientApiStatusBadge as ApiStatusBadge } from '@dotone/react-ui-core';
import ProductApiFormModal from './ProductApiFormModal';

const formatStatus = (cell) => cell && <ApiStatusBadge block value={cell} />;

const formatActions = (row) => (
  <ProductApiFormModal
    clientApi={row}
    buttonProps={{ color: 'link', variant: 'edit', className: 'p-0' }}
  />
);

const getColumns = (t) => [
  {
    dataField: 'name',
    text: t('table.name'),
    sort: true,
  },
  {
    dataField: 'owner',
    text: t('common:model.offer'),
    formatter: (cell) => getNameWithId(cell),
  },
  {
    dataField: 'status',
    text: t('status'),
    sort: true,
    formatter: formatStatus,
  },
  {
    dataField: 'actions',
    text: '',
    isDummyField: true,
    formatter: (cell, row) => formatActions(row),
  },
];

const ProductApiTable = (props) => {
  const { t } = useTranslate('clientApi', 'model');

  const columns = getColumns(t);

  return (
    <TablePaginated
      remote
      condensed
      responsive
      hover
      classes="stylish-table text-sm"
      columns={columns}
      {...props}
    />
  );
};

export default ProductApiTable;
