import { ClientApiApi } from '@api';
import { clientApiTypes as actionTypes } from './types';

export const fetchClientApis = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  ClientApiApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const createClientApi = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE.LOADING,
  });

  ClientApiApi.create({ clientApi: params })
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'add',
      });

      dispatch({
        type: actionTypes.CREATE.SUCCESS,
        payload: response,
        message: 'notify:general.save.success',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE.FAILURE,
        payload: { data: error.data },
        message: 'notify:general.save.error',
      });
    });
};

export const updateClientApi = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE.LOADING,
  });

  ClientApiApi.update(id, { clientApi: params })
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'add',
      });

      dispatch({
        type: actionTypes.UPDATE.SUCCESS,
        payload: response,
        message: 'notify:general.save.success',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE.FAILURE,
        payload: { data: error.data },
        message: 'notify:general.save.error',
      });
    });
};
