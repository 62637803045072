import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from '@dotone/react-ui-core/hooks';
import { useShallowEqualSelector, useTranslate } from '@hooks';
import { createClientApi, updateClientApi } from '@actions';
import { googleProductApiName } from '@constants';
import { FormGroup as BsFormGroup, Col } from 'reactstrap';
import { Label, Input, Form } from '@dotone/react-ui-core';
import ProductApiNameSelect from './ProductApiNameSelect';
import { OfferSearchInput } from '../forms';

const FormGroup = ({ label, required = false, ...otherProps }) => (
  <BsFormGroup row>
    <Col md={4} lg={3} className="text-left text-md-right">
      <Label className="text-custom">
        {label}
        {required && '*'}
      </Label>
    </Col>
    <Col md={8} lg={9} {...otherProps} />
  </BsFormGroup>
);

const ProductApiForm = ({ defaultValues, onCancel, ...otherProps }) => {
  const { t } = useTranslate('clientApi', 'model');
  const dispatch = useDispatch();

  const {
    data = { message: {} },
    isLoading,
    status,
  } = useShallowEqualSelector(({ clientApiCreate, clientApiUpdate }) =>
    defaultValues.id ? clientApiUpdate : clientApiCreate
  );

  const { setError } = useForm({
    defaultValues: { name: googleProductApiName, ...defaultValues },
  });

  const handleSubmit = ({ id, ...values }) => {
    if (id) {
      dispatch(updateClientApi(id, values));
    } else {
      dispatch(createClientApi(values));
    }
  };

  useEffect(() => {
    if (onCancel && status === 'success' && !isLoading) onCancel();
  }, [onCancel, status, isLoading]);

  useEffect(() => {
    if (status === 'error' && data.message) {
      Object.keys(data.message).forEach((key) =>
        setError(key, { type: 'custom', message: data.message[key] })
      );
    }
  }, [status, data.message, setError]);

  return (
    <Form
      forModal
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitting={isLoading}
      {...otherProps}
    >
      <>
        <FormGroup label={t('name')} required>
          <ProductApiNameSelect
            name="name"
            defaultId={defaultValues && defaultValues.id}
            defaultValues={defaultValues}
            rules={{ required: true }}
          />
        </FormGroup>
        <FormGroup label={t('common:model.offer')} required>
          <OfferSearchInput name="ownerId" required active={!defaultValues.id} />
        </FormGroup>
        <FormGroup label={t('host')} required>
          <Input type="textarea" name="host" rules={{ required: true }} />
        </FormGroup>
        <FormGroup label={t('username')}>
          <Input name="username" />
        </FormGroup>
        <FormGroup label={t('password')}>
          <Input name="password" />
        </FormGroup>
      </>
    </Form>
  );
};

export default ProductApiForm;
